import { json, redirect, type LoaderFunctionArgs } from "@remix-run/node"
import {
  useLoaderData,
  type MetaFunction,
  Outlet,
  Link,
} from "@remix-run/react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

import { getUserId } from "~/utils/session.server"
import { getDailySideImageBgUrlClassName } from "~/utils/rendering.server"
import { FielderCopyright } from "~/components/FielderCopyright"

export const meta: MetaFunction = () => {
  return [
    { title: "Fielder | Login" },
    { name: "description", content: "Welcome to Fielder!" },
  ]
}

const namespace = ["common"]
export let handle = {
  // In the handle export, we can add an i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: namespace,
}

export async function loader({ request }: LoaderFunctionArgs) {
  const userId = await getUserId(request)
  const url = new URL(request.url)

  if (
    userId &&
    !url.pathname.includes("/logout") &&
    !url.pathname.includes("/reset-password")
  ) {
    return redirect("/dashboard")
  }

  const dayOfWeek = dayjs().day()
  let sideImageBgUrlClassName = getDailySideImageBgUrlClassName(dayOfWeek)
  return json({ sideImageBgUrlClassName })
}

export default function AuthLayout() {
  const { t } = useTranslation(namespace)
  const { sideImageBgUrlClassName } = useLoaderData<typeof loader>()

  return (
    <div className="flex h-full min-h-screen w-full flex-1">
      <div className="mt-10 flex flex-1 flex-col justify-start px-6 xs:mt-32 sm:p-0 lg:mt-48">
        <div className="mx-auto w-full max-w-sm">
          <Link to="/" className="block">
            <img
              className="mx-auto h-auto w-56 md:w-72"
              src="/images/theme/logo.svg"
              alt="Logo"
            />
          </Link>
          <Outlet />
        </div>
        <div className="mt-20 flex flex-1 flex-col justify-end gap-2 pb-8 text-center text-sm xs:gap-2">
          <FielderCopyright />
          <div className="flex flex-row justify-center gap-4 xs:gap-2">
            <a href="/privacy" target="_blank" className="underline">
              {t("privacyPolicy")}
            </a>
            |
            <a href="/terms" target="_blank" className="underline">
              {t("termsOfService")}
            </a>
          </div>
        </div>
      </div>
      <div className="relative hidden flex-1 justify-center bg-brand-primary-50 lg:flex">
        <div
          className={`w-full bg-cover bg-center bg-no-repeat ${sideImageBgUrlClassName}`}
        ></div>
      </div>
    </div>
  )
}
